<template>
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99441 4.41211H5.5293V4.99654L5.85033 5.25645C7.26307 6.556 7.51882 8.76449 7.51882 10.5829V15.0004H10.5991V10.5839C10.5991 8.76449 10.8553 6.556 12.2671 5.25743L12.5881 4.99752V4.41211H8.99441Z"
      fill="currentColor"
    />
    <path
      d="M9.08735 0H0.235352V0.829014L0.530581 0.725879H1.82978L4.7216 1.0408C4.8825 1.19512 5.02322 1.407 5.13466 1.66276C5.31386 2.07766 5.78381 3.32237 5.78381 3.32237C5.78381 3.32237 5.90163 3.52942 6.19686 3.52942H11.8622C12.157 3.52942 12.2753 3.32237 12.2753 3.32237C12.2753 3.32237 12.8062 2.07766 12.9245 1.66276C13.0359 1.407 13.1766 1.19512 13.3375 1.0408L16.2289 0.725879H17.5281L17.8824 0.829014V0H9.08735Z"
      fill="currentColor"
    />
    <path
      d="M10.7347 0.884766H9.05803H7.42561L6.41113 1.20507L6.98452 2.64947H9.05803H11.1315L11.7053 1.20507L10.7347 0.884766Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
